import { useState, useLayoutEffect } from 'react';

export function useWindowSize(defaultValue) {
  const [windowSize, setWindowSize] = useState({ innerWidth: defaultValue });

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      const handler = () => setWindowSize({ innerWidth: window.innerWidth });
      return window.addEventListener('resize', handler);
    }
  }, []);

  return windowSize.innerWidth;
}
