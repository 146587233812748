import {
  DisabledHeader,
  DisabledTxt,
  GridContent,
  GridImg,
  GridItem,
  GridRow,
  IconRow,
  NewsBox,
  WorkshopType
} from './styled.components';
import {
  Dot,
  Icon,
  Row,
  Tag,
  navigateTo
} from '../../../../components/global/utils';
import React, { useState } from 'react';

import { Button } from '../../../../components/global/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadMoreButton } from '../../../workshops';
import { SubHeader } from '../../../../components/typography';
import disabledWorkshopImg from '../../../../images/news/workshops/disabledWorkshopImage.png';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useWindowSize } from '../../../../utils/useWindowSize';

export function Highlight({ data, type, loadmore }) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language === 'en' ? '/en' : '';
  const [pageCount, setPageCount] = useState(1);
  const [isNewsPage, setIsNewsPage] = useState(false);

  const windowSize = useWindowSize(1123);

  useEffect(() => {
    window.location.pathname === ('/news/' || '/en/news/' || '/news')
      ? setIsNewsPage(true)
      : setIsNewsPage(false);
  }, [isNewsPage]);

  return (
    <>
      <NewsBox className={type}>
        {type === 'news' &&
          data.slice(0 * pageCount, 6 * pageCount).map((item, index) => (
            <GridItem
              key={t(item.node.title)}
              to={item.node.slug ? item.node.slug : item.node.contentful_id}
              title={item.node.title}>
              <GridImg bgImg={item.node.thumbnail.file.url} />
              <GridContent>
                <SubHeader>
                  {
                    (item[index] = item[0]
                      ? item.node.title
                      : String(item.node.title.substring(0, 50) + '...'))
                  }
                </SubHeader>
                <GridRow>
                  {index === 0 && item?.node?.authors !== null && (
                    <img
                      alt={item?.node?.authors[0].title}
                      src={item?.node?.authors[0]?.file?.url}
                    />
                  )}
                  {item.node.authors[0].title}
                  <Dot className="article" />
                  <p>
                    {moment(
                      item?.node?.publishDate ?? item?.node?.createdAt
                    ).format('DD MMM YYYY')}
                  </p>
                </GridRow>
              </GridContent>
            </GridItem>
          ))}
        {/* TODO: FIX THIS THING, THIS IS UGLY AND OVERCOMPLICATED AND UNREADABLE */}
        {type === 'workshops' && data[0].length >= 2
          ? data[0].slice(0, 2).map((item) => (
              <GridItem key={item.node.title} to={`${type}/${item.node.slug}`}>
                <WorkshopType>{t(item.node.type)}</WorkshopType>
                <GridImg bgImg={item.node.thumbnail.file.url} />
                <GridContent>
                  <SubHeader>{t(item.node.title)}</SubHeader>
                  <Row
                    style={{
                      padding: '2rem 0',
                      flexWrap: 'wrap',
                      alignItems: 'center'
                    }}>
                    <IconRow>
                      <Icon name="pin" />
                      {item.node.place}
                    </IconRow>
                    <Dot className="article" />
                    <IconRow>
                      <Icon name="calendar" />
                      {moment(item.node.data).format('DD.MM.YYYY')}
                    </IconRow>
                    <Dot className="article" />
                    <IconRow>
                      <Icon name="people" />
                      {item.node.type.substring(0, 15) + '...'}
                    </IconRow>
                  </Row>
                  <Button
                    className="outlined-solid"
                    onClick={() => navigateTo(item.node.link, '_blank')}>
                    {t`main.sign-in`}
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </GridContent>
              </GridItem>
            ))
          : data[0].length <= 1 && (
              <>
                {data[0].slice(0, 1).map((item) => (
                  <>
                    <GridItem
                      key={item.node.title}
                      to={`${type}/${item.node.slug}`}>
                      <WorkshopType>{t(item.node.type)}</WorkshopType>
                      <GridImg bgImg={item.node.thumbnail.file.url} />
                      <GridContent>
                        <SubHeader>{item.node.title}</SubHeader>
                        <Row
                          style={{
                            padding: '2rem 0',
                            flexWrap: 'wrap',
                            alignItems: 'center'
                          }}>
                          <IconRow>
                            <Icon name="pin" />
                            {item.node.place}
                          </IconRow>
                          <Dot className="article" />
                          <IconRow>
                            <Icon name="calendar" />
                            {moment(item.node.data).format('DD.MM.YYYY')}
                          </IconRow>
                          <Dot className="article" />
                          <IconRow>
                            <Icon name="people" />
                            {item.node.type}
                          </IconRow>
                        </Row>
                        <Button
                          className="outlined-solid"
                          onClick={() => navigateTo(item.node.link, '_blank')}>
                          {t`main.sign-in`}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                      </GridContent>
                    </GridItem>
                  </>
                ))}
                <GridItem className="disabled">
                  <GridImg bgImg={disabledWorkshopImg} />
                  <GridContent>
                    <DisabledHeader>
                      <SubHeader>Wkrótce nowe warsztaty</SubHeader>
                      <DisabledTxt>
                        Odwiedzaj nas! Niedługo nowe wydarzenia!
                      </DisabledTxt>
                    </DisabledHeader>
                    <Button className="disabled">
                      {t`main.sign-in`}
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  </GridContent>
                </GridItem>
              </>
            )}
        {type === 'interviews' &&
          data.slice(0, 5).map((item, index) => (
            <GridItem
              key={item.node.title}
              to={item.node.link}
              target="_blank"
              className={item.node.authors.length > 1 ? 'authors' : 'author'}>
              <GridImg bgImg={item.node.thumbnail.file.url} />
              <GridContent>
                <span className="tags">
                  {(item?.node?.type?.split(', ') ?? [])?.map((tag) => (
                    <Tag key={tag}>{tag}</Tag>
                  ))}
                </span>
                <div className="authorBox">
                  {windowSize < 1123 ? (
                    item.node.authors.map((item) => {
                      return (
                        <span className="authorItem" key={item}>
                          {item}
                        </span>
                      );
                    })
                  ) : (
                    <span
                      className="authorItem"
                      title={String(
                        item.node.authors.map((item) => {
                          return item;
                        })
                      )}>
                      {item.node.authors[0]}
                      {item.node.authors[1] ? ' +1' : null}
                    </span>
                  )}
                </div>
                <SubHeader title={item.node.title}>
                  {index === 0
                    ? item.node.title
                    : item.node.title.substring(0, 20) + '...'}
                </SubHeader>
              </GridContent>
            </GridItem>
          ))}
        {type === 'articles' &&
          data.slice(0 * pageCount, 6 * pageCount).map((item) => (
            <>
              <GridItem
                key={item.node.title}
                to={
                  isNewsPage
                    ? `${currentLanguage}/news/${type}/${item.node.slug}`
                    : `${currentLanguage}/news/articles/${item.node.slug}`
                }>
                <GridImg bgImg={item.node.thumbnail.file.url} />
                <GridContent>
                  <SubHeader title={t(item.node.title)}>
                    {item.node.title.substring(0, 60) + '...'}
                  </SubHeader>
                  <GridRow>
                    <p>{item?.node?.authors[0].title}</p>
                    <Dot className="article" />
                    <p>
                      {moment(item?.node?.publishDate).format('DD MMM YYYY')}
                    </p>
                  </GridRow>
                </GridContent>
              </GridItem>
            </>
          ))}
        {type === 'medtech' &&
          data.slice(0 * pageCount, 3 * pageCount).map((item, index) => (
            <GridItem
              key={item.node.title}
              to={
                isNewsPage
                  ? `${currentLanguage}/news/${type}/${item.node.slug}`
                  : `${currentLanguage}/news/articles/${item.node.slug}`
              }>
              <GridImg bgImg={item.node.thumbnail.file.url} />
              <GridContent>
                <SubHeader>
                  {item.node.title.substring(0, 60) + '...'}
                </SubHeader>
                <GridRow>
                  <p>{item?.node?.authors[0].title}</p>
                  <Dot className="article" />
                  <p>{moment(item?.node?.publishDate).format('DD MMM YYYY')}</p>
                </GridRow>
              </GridContent>
            </GridItem>
          ))}
      </NewsBox>
      {loadmore && (
        <Row style={{ justifyContent: 'center', width: '100%' }}>
          <LoadMoreButton
            className="outlined"
            onClick={() => setPageCount(pageCount + 1)}>
            {t('workshops.load-more')}
          </LoadMoreButton>
        </Row>
      )}
    </>
  );
}
