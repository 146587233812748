import React, { useEffect } from 'react';
import {
  PageSection,
  SectionWrapper
} from '../../../components/global/flexbox';
import { SectionHeaders } from '../styled.components';
import { SectionHeader } from '../../../components/typography';
import { SeeMore } from './highlight/styled.components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Row } from '../../../components/global/utils';

export function HighlightWrapper({ title, component, link, type }) {
  const [isNewsPage, setIsNewsPage] = useState('');

  useEffect(() => {
    window.location.pathname === ('/news/' || '/en/news/' || '/news')
      ? setIsNewsPage(true)
      : setIsNewsPage(false);
  }, [isNewsPage]);

  return (
    <PageSection>
      <SectionWrapper>
        <SectionHeaders>
          <SectionHeader style={{marginTop: 0}}>{title}</SectionHeader>
          {type !== 'news' && isNewsPage ? (
            <SeeMore to={`/news${link}`}>
              <Row className="align-center">
                <span className="hideonmobile">Zobacz więcej</span>
                &nbsp;
                <FontAwesomeIcon icon={faArrowRight} />
              </Row>
            </SeeMore>
          ) : null}
        </SectionHeaders>
        {component}
      </SectionWrapper>
    </PageSection>
  );
}
